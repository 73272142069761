import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Box = styled.div`
  > iframe.hs-form-iframe {
    width: 100% !important;
  }
`

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter, html },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/expression-of-interest.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    new Promise(resolve => {
      const script = document.createElement("script")

      script.src = "//js.hsforms.net/forms/shell.js"
      script.async = true

      document.body.appendChild(script)

      script.addEventListener("load", () => {
        resolve()
      })
    }).then(() => {
      if (window.hbspt)
        window.hbspt.forms.create({
          portalId: "8921221",
          formId: "386d56af-fc20-4cb3-bad5-5a06958af8fd",
          target: ".hbspt-eoi",
          inlineMessage:
            "Thanks for expressing your interest, we'll be in touch once we confirm if you're eligible.",
          onFormSubmitted: () =>
            "Thanks for expressing your interest, we'll be in touch once we confirm if you're eligible.",
        })
    })
  }, [])

  return (
    <Layout meta={frontmatter.meta}>
      <div style={{ maxWidth: "960px", margin: "auto" }}>
        <section>
          <div className="container">
            <div className="row">
              <div className="sub-page-heading">
                <h1>{frontmatter.title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div
                className="blog-text"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row blog-text">
              <Box className="hbspt-eoi"></Box>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
